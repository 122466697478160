import { useState, useEffect, useReducer } from 'react'
// import { Medicine } from '../../../../allContext'
import { medicineReducer, medicineState } from '../../../../reducer/medicineReducer'
import DropDown from '../../../ReUsable/DropDown/DropDown'
import InputField from '../../../ReUsable/InputField/InputField'
import InputNumber from '../../../ReUsable/InputNumber/InputNumber'
import SuggestionMedicine from '../Suggestion/SuggestionMedicine'
import classes from './InputMedicine.module.css'

const InputMedicine = () => {
    const [stateMedicine, dispatchMedicine] = useReducer(medicineReducer, medicineState)
    // const { stateMedicine, dispatchMedicine } = useContext(Medicine)

    const apiV1 = process.env.REACT_APP_API_V1

    const option = [
        { name: 'After Meal', value: 'after' },
        { name: 'Before Meal', value: 'before' },
        { name: 'None', value: '' },
    ]

    const [arr, setArr] = useState([])
    const [medicineObj, setMedicineObj] = useState({ name: '' })

    const [id, setId] = useState(0)
    const [medicine, setMedicine] = useState('')
    const [form, setForm] = useState('')
    const [strength, setStrength] = useState('')
    const [generic, setGeneric] = useState('')
    const [pharma, setPharma] = useState('')

    const [morning, setMorning] = useState('')
    const [afterNoon, setAfterNoon] = useState('')
    const [evening, setEvening] = useState('')
    const [night, setNight] = useState('')

    const [input, setInput] = useState('')
    const [time, setTime] = useState('')

    const [after, setAfter] = useState('')
    const [day, setDay] = useState(0)
    const [remark, setRemark] = useState('')
    const [doses, setDoses] = useState('')
    const [daysContinue, setDaysContinue] = useState(false)

    useEffect(() => {
        //Fetch from Api
        const funFetch = async () => {
            try {
                const response = await fetch(`${apiV1}/medicines/?search_medicine=${medicine}&skip=0&limit=100`)
                if (response.ok) {
                    const data = await response.json()
                    setArr(data)
                }
            } catch (err) {}
        }
        if (medicine.length !== 0) {
            funFetch()
        }
        //Medicine name set from object
        if (medicineObj.name && medicineObj.name !== medicine) {
            setId(medicineObj.id)
            setMedicine(medicineObj.name)
            setForm(medicineObj.form)
            setStrength(medicineObj.strength)
            setGeneric(medicineObj.generic)
            setPharma(medicineObj.pharmaceuticals)
            setMedicineObj({ ...medicineObj, name: '' })
        }
    }, [medicineObj, medicine, apiV1])

    // doses selects
    const selects = [
        { id: 1, label: 'qd (once a day)' },
        { id: 2, label: 'q_h (once every x hours)' },
        { id: 3, label: 'bid (twice a day)' },
        { id: 4, label: 'tid (three times a day)' },
        { id: 5, label: 'qds (four times a day)' },
        { id: 6, label: 'qd_am (once every x AM)' },
        { id: 7, label: 'qd_pm (once every x PM)' },
        { id: 8, label: 'qad (once every x other day)' },
        { id: 9, label: 'q_wk (once every x weeks)' },
        { id: 10, label: 'prn (as needed)' },
    ]

    const [selected, setSelected] = useState(1)

    useEffect(() => {
        let dosesString = ''

        if (selected === 2) {
            dosesString = `${input || 0} every ${time} hours`
        } else if (selected === 5) {
            dosesString = `${morning || 0} + ${afterNoon || 0} + ${evening || 0}  + ${night || 0}`
        } else if (selected === 6) {
            dosesString = `${input || 0} every day at ${time} AM`
        } else if (selected === 7) {
            dosesString = `${input || 0} every day at ${time} PM`
        } else if (selected === 8) {
            dosesString = `${input || 0} every ${time} days`
        } else if (selected === 9) {
            dosesString = `${input || 0} every ${time} weeks`
        } else if (selected === 10) {
            dosesString = `${input || 0} as needed`
        } else {
            dosesString = `${morning || 0} + ${evening || 0}  + ${night || 0}`
        }

        setDoses(dosesString)
    }, [selected, morning, afterNoon, evening, night, input, time])

    const submit = (e) => {
        e.preventDefault()

        dispatchMedicine({
            type: 'input',
            payload: [
                ...stateMedicine.medicine,
                {
                    id,
                    name: medicine,
                    form,
                    strength,
                    generic,
                    pharma,
                    doses: doses,
                    after,
                    day: isNaN(day) ? 0 : day,
                    days_continue: daysContinue,
                    remark,
                },
            ],
        })
        setId(0)
        setMedicine('')
        setForm('')
        setStrength('')
        setGeneric('')
        setMorning(0)
        setEvening(0)
        setNight(0)
        setAfter('')
        setDay('')
        setDaysContinue(false)
        setRemark('')

        window.location.reload()
    }

    return (
        <div className={classes.InputMedicine}>
            <form>
                <div className={classes.formWrap}>
                    <InputField text={medicine} setText={setMedicine} label="Medicine name" />
                    {medicine.length !== 0 ? (
                        arr.length !== 0 && !arr.some((obj) => obj.id === id) ? (
                            <SuggestionMedicine objArr={arr} setObj={setMedicineObj} />
                        ) : null
                    ) : null}

                    <div className={classes.typeWrap}>
                        <InputField text={form} setText={setForm} label="Form" />
                        <InputField text={strength} setText={setStrength} label="Strength" />
                        <InputField text={generic} setText={setGeneric} label="Generic name" />
                    </div>

                    <div className={classes.select}>
                        <select value={selected} onChange={(e) => setSelected(parseInt(e.target.value))}>
                            {selects.map((item, i) => (
                                <option key={i} value={item.id}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className={classes.timeWrap}>
                        {selects.some(
                            (item) =>
                                item.id === selected &&
                                (item.id === 2 || item.id === 6 || item.id === 7 || item.id === 8 || item.id === 9)
                        ) ? (
                            <>
                                <InputNumber
                                    num={time}
                                    setNum={setTime}
                                    label={
                                        selected === 2
                                            ? 'Hour'
                                            : selected === 6
                                            ? 'AM'
                                            : selected === 7
                                            ? 'PM'
                                            : selected === 8
                                            ? 'Day'
                                            : selected === 9
                                            ? 'Weeks'
                                            : ''
                                    }
                                    negetive={false}
                                    step={'0.1'}
                                />
                                <InputNumber
                                    num={input}
                                    setNum={setInput}
                                    label="No. of Tablets"
                                    negetive={false}
                                    step={'0.1'}
                                />
                            </>
                        ) : selects.some((item) => item.id === selected && item.id === 5) ? (
                            <>
                                <InputNumber
                                    num={morning}
                                    setNum={setMorning}
                                    label="Morning"
                                    negetive={false}
                                    step={'0.1'}
                                />
                                <InputNumber
                                    num={afterNoon}
                                    setNum={setAfterNoon}
                                    label="Afternoon"
                                    negetive={false}
                                    step={'0.1'}
                                />
                                <InputNumber
                                    num={evening}
                                    setNum={setEvening}
                                    label="Evening"
                                    negetive={false}
                                    step={'0.1'}
                                />
                                <InputNumber
                                    num={night}
                                    setNum={setNight}
                                    label="Night"
                                    negetive={false}
                                    step={'0.1'}
                                />
                            </>
                        ) : selects.some((item) => item.id === selected && item.id === 10) ? (
                            <InputNumber
                                num={input}
                                setNum={setInput}
                                label="No. of Tablets"
                                negetive={false}
                                step={'0.1'}
                            />
                        ) : (
                            <>
                                <InputNumber
                                    num={morning}
                                    setNum={setMorning}
                                    label="Morning"
                                    negetive={false}
                                    step={'0.1'}
                                />
                                <InputNumber
                                    num={evening}
                                    setNum={setEvening}
                                    label="Evening"
                                    negetive={false}
                                    step={'0.1'}
                                />
                                <InputNumber
                                    num={night}
                                    setNum={setNight}
                                    label="Night"
                                    negetive={false}
                                    step={'0.1'}
                                />
                            </>
                        )}
                    </div>
                    <div className={classes.daysWrap}>
                        <DropDown drop={after} setDrop={setAfter} arr={option} />
                        <InputNumber text={day} setNum={setDay} label="Day" negetive={false} />
                    </div>

                    <di className={classes.continue}>
                        <input
                            type="checkbox"
                            id="continue"
                            value={daysContinue}
                            onChange={(e) => setDaysContinue(e.target.checked)}
                        />
                        <label htmlFor="continue">Continue</label>
                    </di>
                </div>
                <InputField text={remark} setText={setRemark} label="Remark" />
                <button onClick={submit}>Submit</button>
            </form>
        </div>
    )
}

export default InputMedicine
